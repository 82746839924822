import { products } from "./productsHook";
import { newProducts } from "./newArrivalsHook";
import { useState, useEffect } from "react";
import banner2 from "../images/banner.jpg";
import ProductCard from "./ProductCard";
import FilteredProductCard from "./FilteredProductCard";

const ProductFilter = ({ category, brand }) => {
  const getUniqueProducts = (products) => {
    const uniqueMap = new Map();

    products
      .filter((p) => {
        if (brand === "Apple" && category === "View All") {
          return (
            p.brand === "Apple" && !p.product_eng.includes("Apple Gift Card")
          );
        } else {
          return p.category_1 === "Gift Card";
        }
      })
      .forEach((product) => {
        const key = product.product_eng.split(" ").slice(0, 2).join(" ");
        if (!uniqueMap.has(key)) {
          uniqueMap.set(key, product);
        }
      });

    return Array.from(uniqueMap.values()).sort((a, b) =>
      brand === "Apple" && category === "View All"
        ? b.product_eng.localeCompare(a.product_eng)
        : a.product_eng.localeCompare(b.product_eng)
    );
  };

  const [filteredProducts, setFilteredProducts] = useState(null);

  const handleClick = (name) => {
    const appleProductCategory = name.split(" ").slice(0, 2).join(" ");

    const filteredProducts = products.filter((p) =>
      p.product_eng.includes(appleProductCategory)
    );

    setFilteredProducts(filteredProducts);
  };

  return (
    <>
      {filteredProducts ? (
        <FilteredProductCard filteredProducts={filteredProducts} />
      ) : (
        getUniqueProducts(products).map((p) => (
          <div
            onClick={() => handleClick(p.product_eng)}
            key={p.id}
            className="flex flex-col items-center cursor-pointer rounded-lg mt-4 mr-4 p-1 max-w-sm bg-white shadow"
          >
            <img
              className="object-contain h-52 m-auto rounded-lg p-2"
              src={p.product_image_1 === "" ? p.logo_image : p.product_image_1}
              alt="products"
            />
            <h5 className="mb-2 text-md font-bold tracking-tight text-gray-900">
              {brand === "Apple" && category === "View All"
                ? p.product_eng.split(" ").slice(0, 2).join(" ")
                : p.product_eng.split(" ").slice(0, 3).join(" ")}
            </h5>
          </div>
        ))
      )}
    </>
  );
};

export default ProductFilter;

export const appleProduct = [];
