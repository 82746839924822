import Papa from "papaparse";
import ProductCard from "./ProductCard";
import Nav from "./Nav";
import { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import CsvContainer from "./CsvContainer";
import Product from "./Product";
import ImageDownloader from "./ImageDownloader";
import ProductFilter from "./ProductFilter";

const HomeContainer = () => {
  const [category, setCategory] = useState("View All");
  const [brand, setBrand] = useState("View All");


  // const handleFileUpload = (e) => {
  //   const file = e.target.files[0];

  //   if (file) {
  //     Papa.parse(file, {
  //       complete: (result) => {
  //         const jsonData = result.data;

  //         const key = jsonData.shift();
  //         const parsedData = jsonData.slice(0).map((data) => {
  //           return key.reduce((obj, currentKey, index) => {
  //             obj[currentKey] = data[index];
  //             return obj;
  //           }, {});
  //         });

  //         console.log(parsedData);
  //       },
  //     });
  //   }
  // };

  return (
    <>
      <Nav onCategory={setCategory} onBrand={setBrand} />
      <Routes>
        <Route
          path="/"
          element={<ProductCard category={category} brand={brand} onBrand={setBrand} />}
        />
        {/* <Route path="/csv" element={<CsvContainer />} /> */}
        <Route path=":productId" element={<Product />} />
        <Route path="/imageDownloader" element={<ImageDownloader />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      {/* <input type="file" accept=".csv" onChange={handleFileUpload} /> */}
    </>
  );
};

export default HomeContainer;
