import { useNavigate } from "react-router-dom";

const FilteredProductCard = ({ filteredProducts }) => {
  const navigate = useNavigate();

  return (
    <>
      {filteredProducts.map((p) => (
        <div
          key={p.id}
          className="cursor-pointer mt-3 mr-3 p-3 max-w-sm bg-white shadow"
        >
          <div className="rounded-t-lgh-64 ">
            <img
              onClick={() => {
                navigate(p.id);
              }}
              className="object-contain h-64 m-auto rounded-t-lg p-3"
              src={p.product_image_1 === "" ? p.logo_image : p.product_image_1}
              alt="products"
            />
          </div>
          <div className="p-3">
            <h5 className="mb-2 text-md font-bold tracking-tight text-gray-900">
              {p.product_eng}
            </h5>

            {/* Price & Button to add to csv */}
            <div className="flex items-center justify-between mt-4">
              <span className="text-sm font-bold text-gray-900">
                {p.rrp_with_vat}
              </span>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default FilteredProductCard;
